<template>
  <Layout>
    <section class="csms-charge-place">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Summary Start -->
      <div class="row">
        <div class="col-xl-3">
          <SummaryCard :title="$_formatter.convertedTitle('chargePlace', 'cpStatus')"
                       :items="[
                         { name: $t('common.total'), value: infoItems.cpTotal },
                         { name: $t('common.newToday'), value: infoItems.cpNewToday }
                       ]" />
        </div>
        <div class="col-xl-3">
          <SummaryCard :title="$_formatter.convertedTitle('chargePlace', 'cpJoinPlan')"
                       :items="[
                         { name: $t('common.buyPlan'), value: infoItems.buyPlan },
                         { name: $t('common.zeroPlan'), value: infoItems.zeroPlan }
                       ]" />
        </div>
        <div class="col-xl-6">
          <SummaryCard :title="$_formatter.convertedTitle('chargePlace', 'cbStatus')"
                       :items="infoItems.cbCountItems" />
        </div>
      </div>
      <!-- Summary End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3">
                      <label class="form-label" for="cp-search-charger-type">{{ $t('chargePlace.chargerType') }}</label>
                      <multiselect id="cp-search-charger-type"
                                   label="text" track-by="value"
                                   v-model="searchForm.chargerType"
                                   :options="chargerTypeOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="cp-search-name">{{ $t('chargePlace.placeName') }}</label>
                      <input type="text" class="form-control" id="cp-search-name"
                             v-model="searchForm.cpName" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="cp-search-address">{{ $t('chargePlace.address') }}</label>
                      <input type="text" class="form-control" id="cp-search-address"
                             v-model="searchForm.address" />
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('chargePlace.noCp')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`chargePlace.${data.column}`) }}</span>
                  </template>

                  <template #cell(address)="data">
                    <span>{{ $_utils.textLengthOverCut(data.item.address, 30, null) }}</span>
                  </template>
                  <template #cell(detailLocation)="data">
                    <span>{{ !$_utils.isEmpty(data.item.detailLocation) ? $_utils.textLengthOverCut(data.item.detailLocation, 30, null) : '-' }}</span>
                  </template>
                  <template #cell(cbInfo)="data">
                    <template v-if="data.item.chargerTypeCounts && data.item.chargerTypeCounts.length > 0">
                      <div v-for="(item, i) in data.item.chargerTypeCounts" :key="`chargerType_${i}`">
                        <div>
                          <span class="pe-2">{{ $_formatter.toLowerCaseString(item.chargerType) === 'fast' ? $t('common.fast') : $t('common.slow') }}</span>
                          <span>{{ item.count }}</span>
                        </div>
                      </div>
                    </template>
                  </template>
                  <template #cell(cpPlan)="data">
                    <span>{{ data.item.chargePlacePlan ? data.item.chargePlacePlan.planName : '-' }}</span>
                  </template>
                  <template #cell(hqName)="data">
                    {{ data.item.hqBusiness ? data.item.hqBusiness.hqName : '-' }}
                  </template>
                  <template #cell(hqManager)="data">
                    {{ data.item.hqRepresentAccount ? data.item.hqRepresentAccount.name : '-' }}
                  </template>

                  <template #cell(etc)="data">
                    <router-link role="button"
                                 :to="{path: `/charge/place/view/${data.item.placeId}`}"
                                 class="btn btn-sm btn-outline-primary w-sm">{{ $t('btn.view') }}</router-link>
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

              <!-- Bottom Button Start -->
              <template v-if="$_app.user.roles[0] !== 'CP'">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="float-end">
                      <b-button variant="success" @click="create">
                        <i class="uil uil-plus me-2"></i>
                        <span>{{ $t('btn.create') }}</span>
                      </b-button>
                    </div>
                  </div>
                </div>
              </template>
              <!-- Bottom Button End -->

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

      <create-modal ref="cpCreateModal" @callback="getItems" />
    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import SummaryCard from '@/views/components/SummaryCard.vue';
import CreateModal from '@/views/pages/charge-place/CpCreateModal.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, SummaryCard, CreateModal
  },
  data() {
    return {
      title: 'chargePlace',
      infoItems: {
        cpTotal: 0,
        cpNewToday: 0,
        buyPlan: 0,
        zeroPlan: 0,
        cbTotal: 0,
        cbCountItems: []
      },
      chargerTypeOptions: [
        { text: i18n.t('common.total'), value: null },
        { text: i18n.t('common.slow'), value: 'SLOW' },
        { text: i18n.t('common.fast'), value: 'FAST' },
      ],
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        chargerType: { text: i18n.t('common.total'), value: null },
        cpName: null,
        address: null
      },
      fields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'placeName', label: '충전소명', class: 'text-center' },
        { key: 'address', label: '주소' },
        { key: 'detailLocation', label: '상세 위치' },
        { key: 'useTime', label: '운영 시간', class: 'text-center' },
        { key: 'cbInfo', label: '충전기 정보', class: 'text-center' },
        { key: 'cpPlan', label: '충전소 플랜', class: 'text-center' },
        { key: 'hqName', label: '사업 본부명', class: 'text-center' },
        { key: 'hqManager', label: '사업 본부 담당자', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' },
        { key: 'etc', label: '', class: 'text-center' }
      ],
      items: null
    }
  },
  mounted() {
    const vm = this;

    vm.showOverlay();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    async getItems() {
      const vm = this;

      await Promise.all([
        vm.getInfoItems(),
        vm.getListItems()
      ]);
      vm.hideOverlay();
    },
    getInfoItems() {
      const vm = this;
      const _url = '/onmapi/view/Summary/place';

      return new Promise((resolve, reject) => {
        http.get(_url).then(res => {
          const _item = res.data.item;

          vm.infoItems = {
            cpTotal: _item.placeTotalCount,
            cpNewToday: _item.placeTodayCount,
            buyPlan: _item.planCount.purchaseCount,
            zeroPlan: _item.planCount.zeroCount,
            cbCountItems: []
          }

          vm.infoItems.cbCountItems = [
            { name: this.$t('common.total'), value: _item.chargePointTotalCount, divide: true },
            ...vm.createCbItem(i18n.t('common.slow'), _item.slowDetailCount),
            ...vm.createCbItem(i18n.t('common.fast'), _item.fastDetailCount)
          ];

          resolve(res);
        }).catch(err => {
          console.error('Charge Place Summary Error :: ', err)
          reject(err);
        });
      });
    },
    createCbItem(chargerType, items) {
      return items.map(ele => ({
        name: `${chargerType} ${ele.maxKw}kW`,
        value: ele.count
      }));
    },
    getListItems() {
      const vm = this;
      const _url = '/onmapi/view/ChargePlace/list';

      return new Promise((resolve, reject) => {
        http.post(_url, {
          page: vm.pagination.currentPage,
          limit: vm.pagination.perPage,
          searchConditions: vm.searchConditions,
          sortCondition: { sortDirection: vm.sortDirection }
        }).then(res => {
          vm.items = res.data.items;
          vm.pagination.totalCount = res.data.totalCount;
          resolve(res);
        }).catch(err => {
          console.error('Charge Place List Error :: ', err)
          reject(err);
        });
      });
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    },
    reset() {
      const vm = this;

      vm.searchForm = {
        chargerType: { text: i18n.t('common.total'), value: null },
        cpName: null,
        address: null
      };
      vm.searchConditions = [];

      vm.getListItems();
    },
    search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.chargerType.value)) {
        vm.searchConditions.push({ searchKey: 'chargerType', searchValue: vm.searchForm.chargerType.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.cpName)) {
        vm.searchConditions.push({ searchKey: 'placeName', searchValue: vm.searchForm.cpName });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.address)) {
        vm.searchConditions.push({ searchKey: 'address', searchValue: vm.searchForm.address });
      }

      vm.pagination.currentPage = 1;
      vm.getListItems();

      /*if (this.$_utils.isEmpty(vm.searchForm.chargerType.value)
          && this.$_utils.isEmpty(vm.searchForm.cpName)
          && this.$_utils.isEmpty(vm.searchForm.address)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];

        if (!this.$_utils.isEmpty(vm.searchForm.chargerType.value)) {
          vm.searchConditions.push({ searchKey: 'chargerType', searchValue: vm.searchForm.chargerType.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.cpName)) {
          vm.searchConditions.push({ searchKey: 'placeName', searchValue: vm.searchForm.cpName });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.address)) {
          vm.searchConditions.push({ searchKey: 'address', searchValue: vm.searchForm.address });
        }

        vm.pagination.currentPage = 1;
        vm.getListItems();
      }*/
    },
    create() {
      const vm = this;

      vm.$nextTick(() => {
        this.$refs.cpCreateModal.show();
      });
    }
  }
}
</script>
